import { z } from 'zod'

import {
  defaultActionsSchema,
  senderIdApiItem,
  senderIdSelectorItem,
} from 'app/system/senderid/models/form-model'
import { TableRequestOptions } from 'app/types/app'
import { StatusEnumType } from 'app/types/zod/common/Enums'
import { parsedTableRequest } from 'app/types/zod/utils'
import { parsedRequest } from 'app/types/zod/utils'
import { API } from './API'
import { getPageNumber, getTableQueryHeaders } from '.'

export type SenderIdPayload = {
  registered_sender_id: {
    id?: number
    sender_id: string
    status: StatusEnumType
    action: string
    whitelisted_sender_id: {
      id?: number
      registeredsenderid_id?: number
      customers: {
        customer_id: number
      }[]
    }
  }
}

export class SenderId {
  api: API
  constructor(api: API) {
    this.api = api
  }

  getSenderIdTableList = parsedTableRequest(
    senderIdApiItem,
    async (options: TableRequestOptions) => {
      const headers = getTableQueryHeaders(options)
      return this.api.get(
        `mgmt/senderid?page=${getPageNumber(options)}`,
        {},
        true,
        headers
      )
    }
  )

  // used as sender option in message applications
  getAvailableSenderIds = parsedRequest(
    z.array(senderIdSelectorItem),
    async (customerId: number) => {
      const result = await this.api.get('service/senderid', {}, true, {
        'X-Customer': JSON.stringify(customerId),
      })
      return result
    }
  )

  getSenderId = parsedRequest(senderIdApiItem, async (id: string) => {
    const result = await this.api.get(`mgmt/senderid/${id}`)
    return result
  })

  getDefaultActions = parsedRequest(defaultActionsSchema, async () => {
    const result = await this.api.get('mgmt/senderid/defaultaction')
    return result
  })

  saveSenderId = parsedRequest(
    senderIdApiItem,
    async (payload: SenderIdPayload) => {
      const { id } = payload.registered_sender_id
      if (!id) {
        const result = await this.api.post('mgmt/senderid', payload)
        return result
      } else {
        const result = await this.api.put(`mgmt/senderid/${id}`, payload)
        return result
      }
    }
  )

  async deleteSenderId(id: number) {
    await this.api.delete(`mgmt/senderid/${id}`)
  }
}
